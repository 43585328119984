@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* STYLE MAPS */
.map-container {
  height: 300px;
  /* Ajusta la altura según sea necesario */
  width: 100%;
  /* O ajusta el ancho según sea necesario */
}

/* SECTION LOGIN  */
.section-redarkia {
  height: 100%;
  background: rgb(201, 38, 71);
  background: linear-gradient(42deg, rgba(201, 38, 71, 1) 8%, rgba(52, 32, 143, 1) 51%, rgba(69, 252, 192, 1) 93%);
}

.container-redarkia {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.sjt {
  border-radius: 1rem;
}

.sjt-logo {
  color: #ff6219;
}

.banner-redarkia {
  border-radius: 5px;
  height: 100%;
  background-image: radial-gradient(circle at 74.18% -16.45%, #6dc8e3 0, #4cb6d6 25%, #0ba4c9 50%, #0092bc 75%, #0081b0 100%);
}

.text-color-redarkia-h1 {
  color: rgb(5, 12, 36);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.text-color-redarkia-p {
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif;
  font-weight: 390;
  font-style: normal;
  font-size: 18px;
}

.text-h1 {
  padding-top: 30%;
}

.text-p {
  padding-bottom: 10%;
}

.btn-redarkia-web {
  background-color: rgb(5, 12, 36);
  color: #fff;
  font-weight: 500;
  border-radius: 40px;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.4s ease;
}

.btn-redarkia-web:hover {
  background-color: rgb(1, 2, 7);
  color: #fff;
  transition: 0.4s ease;
}

.img-redarkia {
  border-radius: 15px;
}

@media (max-width: 480px) {
  .container-redarkia {
    width: 100%;
    padding-top: 50%;
    position: static;
    /* Cambiar posición si es necesario */
    transform: none;
    /* Restablecer transformación si es necesario */
  }
}

@media (max-width: 768px) {
  .container-redarkia {
    width: 100%;
    padding-top: 20%;
    position: static;
    /* Cambiar posición si es necesario */
    transform: none;
    /* Restablecer transformación si es necesario */
  }
}


/*FOR CARD EVENTS*/

.card-events {
  top: 0px;
  border: 0px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: box-shadow 0.3s ease, top 0.6s ease;
}

.card-events:hover {
  top: -10px;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease, top 0.6s ease;
}

.card-img-events {
  objectFit: cover;
  cursor: pointer;
}

.content-title-redarkia {
  height: 60px;
}

.content-description-redarkia {
  height: 80px;
}

/* For Spaced Numbers in Input Password Code*/
.spaced-input {
  letter-spacing: 0.5em;
  /* Ajusta la cantidad de espacio entre los caracteres según tu preferencia */
}

/*For disabled change color*/

.countDisabled:disabled {
  color: #be0a0a;
  /* Cambia el color de texto */
  background-color: #950a0a;
  /* Cambia el color de fondo */
  /* Puedes agregar otros estilos según tus necesidades */
}

/*Style for Step*/

.indicator {
  display: flex;
  align-items: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.circleStep {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.circleStep.activo {
  background-color: #007bff;
  color: #fff;
}

.linea {
  height: 2px;
  background-color: #ddd;
  flex: 1;
  transition: background-color 0.3s;
}

.linea.activo {
  background-color: #007bff;
}

.step {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.3s ease-in-out, opacity 0.6s ease-in-out;
}

.step.activo {
  height: auto;
  /* o ajusta a la altura máxima que esperas */
  opacity: 1;
}

/*For card of schedules*/
@keyframes blink {
  50% {
    opacity: 0.6;
  }
}

.blink-effect {
  animation: blink 1.3s infinite;
}

.card-redarkia-col {
  height: 80px;
}

.ReactCrop__image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.canvas-redarkia {
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  width: 50%;
  margin-top: 2%;
}


/*Style for statistic*/

.bg-statistic-redarkia {
  background-color: #212631;
  border: #52565e 1px solid !important;
}

/*Banner for public*/

.contenedor-imagen {
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor-imagen-banner {
  width: 100%;
  height: auto;
  display: block;
}

/*For avatar in profile*/

/*.container-avatar-profile{
  position: relative;
  width: 50vmin;
  max-width: 200px; 
  padding-top: 50%; 
  overflow: hidden;
  border-radius: 50%;
}*/

.container-avatar-profile {
  position: relative;
  width: 50vmin;
  max-width: 200px;
  height: 50vmin;
  /* Altura igual a la mitad del ancho para mantener un aspecto circular */
  max-height: 200px;
  /* Otra vez, puedes establecer un máximo según tus preferencias */
  overflow: hidden;
  border-radius: 50%;
}

.avatar-profile {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  object-fit: cover;
}

.btn-edit-avatar {
  background-color: #0a957e;
  justify-content: center;
}

.btn-edit-avatar:hover {
  background-color: #0b584c;
}

/*Menu Sidebar*/
.menu-redarkia-cursor {
  cursor: pointer;
}

/*Scroll in canvas chart*/

.scrollcon {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .widtgCanvaMobile{
    width: 250% !important;
    margin-left: auto;
    margin-right: auto;
  }
}