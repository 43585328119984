/*
 =====
 UIA-TIMELINE
 =====
 */

.uia-timeline__container {
   display: var(--uia-timeline-display, grid);
}

.uia-timeline__groups {
   display: var(--uia-timeline-groups-display, grid);
   gap: var(--uia-timeline-groups-gap, 1rem);
}

/*
 SKIN 3
 */

[data-uia-timeline-skin="3"] {
   --_uia-timeline-line-color_default: #222;
   --_uia-timeline-space: var(--uia-timeline-space, 1rem);
   --_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 2px);
   --_uia-timeline-point-line-position: var(--uia-timeline-point-line-position, 1rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__container {
   position: relative;
   gap: var(--uia-timeline-annual-sections-gap, 1rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__line {
   inline-size: var(--_uia-timeline-line-thickness);
   block-size: 100%;
   background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));
   position: absolute;
   inset-block-start: 0;
   inset-inline-start: 0;
}

[data-uia-timeline-skin="3"] .uia-timeline__annual-sections {
   display: grid;
   gap: 1.5rem;
}

[data-uia-timeline-skin="3"] .uia-timeline__groups {
   padding-inline-start: calc(var(--_uia-timeline-space));
}

[data-uia-timeline-skin="3"] .uia-timeline__group {
   position: relative;
   isolation: isolate;
}

/* [data-uia-timeline-skin="3"] .uia-timeline__point {
   background-color: var(--uia-timeline-point-background-color, #fff);
} */

[data-uia-timeline-skin="3"] .uia-timeline__point::before {
   content: "";
   inline-size: 1.5%;
   block-size: var(--_uia-timeline-line-thickness);
   background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

   position: absolute;
   inset-block-start: var(--_uia-timeline-point-line-position);
   inset-inline-start: calc(-1 * var(--_uia-timeline-space));
   z-index: -1;
}

[data-uia-timeline-skin="3"] .uia-timeline__year {
   inline-size: fit-content;
   padding: var(--uia-timeline-year-padding, .25rem .75rem);
   background-color: var(--uia-timeline-year-background-color, var(--_uia-timeline-line-color_default));
   color: var(--uia-timeline-year-color, #f0f0f0);
}

[data-uia-timeline-adapter-skin-3="ui-card-skin-#1"] {
   --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
   --uia-card-border-thickness: var(--uia-timeline-point-border-thickness, 3px);
   --uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));
   --uia-card-background-color: var(--uia-timeline-point-background-color);
}

/*
 =====
 UIA-CARD
 =====
 */

.uia-card__time-divider::before {
   content: "—";
   margin-inline: var(--uia-card-time-divider-margin-inline, .15rem);
}

[data-uia-card-skin="1"] .uia-card__container {
   display: grid;
   gap: .5rem;
   padding: var(--uia-card-padding, 1rem 1.75rem);

   background-color: var(--uia-card-background-color);
   border-radius: var(--uia-card-border-radius, 2px);
}

[data-uia-card-skin="1"] .uia-card__intro {
   display: grid;
   gap: var(--uia-card-intro-gap, 1rem);
}

[data-uia-card-skin="1"] .uia-card__time {
   grid-row: 1 / 1;
   inline-size: fit-content;
   padding: var(--uia-card-time-padding, .25rem 1.25rem .25rem);
   background-color: var(--uia-card-time-background-color, #f0f0f0);

   font-weight: var(--uia-card-time-font-weight, 700);
   font-size: var(--uia-card-time-font-size, .75rem);
   text-transform: var(--uia-card-time-text-transform, uppercase);
   color: var(--uia-card-time-color, currentColor);
}

[data-uia-card-skin="1"][data-uia-card-mod="1"] .uia-card__container {
   border-inline-start: var(--uia-card-border-thickness, 2px) var(--uia-card-border-style, solid) var(--uia-card-border-color, currentColor);
   box-shadow: var(--uia-card-box-shadow, 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24));
}

/*
 =====
 DEMO
 =====
 */

:root {
   --uia-timeline-line-color: #4557bb;
   --uia-timeline-dot-color: #4557bb;
   --uia-timeline-arrow-color: #4557bb;
   --uia-timeline-line-thickness: 3px;
   --uia-timeline-point-border-color: #4557bb;
   --uia-timeline-group-padding: 1.5rem 1.5rem 1.25rem;
}

[data-uia-timeline-skin="3"] {
   --uia-timeline-year-background-color: #4557bb;
}

.page {
   max-inline-size: 80ch;
   padding: 5rem 2rem 3rem;
   margin-inline: auto;
}

.linktr {
   display: flex;
   justify-content: flex-end;
   padding: 2rem;
   text-align: center;
}

.linktr__goal {
   background-color: rgb(209, 246, 255);
   color: rgb(8, 49, 112);
   box-shadow: rgb(8 49 112 / 24%) 0 2px 8px 0;
   border-radius: 2rem;
   padding: .75rem 1.5rem;
}

/* STYLE DE LOS BOTONES DE Schedule */
.btn-edit-dateSchedule{
   position: absolute;
   top: -20px;
   right: -10px;
   cursor: pointer;
   transition: top 0.1s ease;
}

.btn-edit-schedule,
.btn-delete-schedule {
   position: absolute;
   top: -15px;
   cursor: pointer;
   transition: top 0.1s ease;
}

.btn-edit-schedule {
   right: -10px;
}

.btn-delete-schedule {
   right: 30px;
}

.btn-edit-schedule:hover {
   top: -17px;
   transition: top 0.1s ease;
}

.btn-delete-schedule:hover {
   top: -17px;
   transition: top 0.1s ease;
}

/* STYLE DE LOS BOTONES DE SPEAKERS */
.card-speaker {
   height: 80%;
}

.btn-edit-speaker,
.btn-delete-speaker {
   position: absolute;
   top: -10px;
   cursor: pointer;
   transition: top 0.1s ease;
}

.btn-edit-speaker {
   right: -10px;
}

.btn-edit-speaker:hover {
   top: -12px;
   transition: top 0.1s ease;
}

.btn-delete-speaker {
   right: 20px;
}

.btn-delete-speaker:hover {
   top: -12px;
   transition: top 0.1s ease;
}

/* Button For Add and Remove in Modal */

.btn-remove-callout {
   position: relative;
   top: -35px;
   right: -98%;
   cursor: pointer;
}

.container-add {
   display: flex;
   align-items: center;
}

.line-add {
   flex: 1;
   height: 3px;
   background-color: #4caf54;
   margin: 0 5px;
}

.button-add {
   height: 100%;
   color: white;
   border-radius: 50%;
   cursor: pointer;
}